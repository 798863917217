/* eslint-disable no-nested-ternary */
// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

const isLocal = false;
const isDesk = true;

const { hostname } = window.location;

export const isXtechno =
  hostname === 'app.xtechnosys.com' || hostname === 'app.xtechnosys' || hostname === 'www.app.xtechnosys.com';
export const isblackben =
  hostname === 'whatsapp.blackben.in' || hostname === 'whatsapp.blackben' || hostname === 'www.whatsapp.blackben.in';
export const isUPM = hostname === 'upmapps.in' || hostname === 'www.upmapps.in' || hostname === 'upmapps';
export const isSukum =
  hostname === 'mywhatsapp.sukamapps.com' ||
  hostname === 'www.mywhatsapp.sukamapps.com' ||
  hostname === 'mywhatsapp.sukamapps';

export const isShyamInfo =
  hostname === 'wa.shyaminfotech.com' || hostname === 'wa.shyaminfotech' || hostname === 'www.wa.shyaminfotech.com';

export const isKglCorp =
  hostname === 'www.whatspro.whatscrmdj.online' ||
  hostname === 'whatspro.whatscrmdj.online' ||
  hostname === 'whatspro.whatscrmdj';

export const isQruplus =
  hostname === 'whatsapp.ranjeetsaini.com' ||
  hostname === 'www.whatsapp.ranjeetsaini.com' ||
  hostname === 'whatsapp.ranjeetsaini';

export const isWhatsEra =
  hostname === 'app.whatsera.com' || hostname === 'www.app.whatsera.com' || hostname === 'app.whatsera';

export const isMorphysms =
  hostname === 'whatsapp.morphysms.com' ||
  hostname === 'www.whatsapp.morphysms.com' ||
  hostname === 'morphysms' ||
  hostname === 'morphysms.com' ||
  hostname === 'www.morphysms.com';

export const isSendPost = hostname === 'sendpost.in' || hostname === 'www.sendpost.in' || hostname === 'sendpost';

export const isPromotezap =
  hostname === 'app.promotezap.com' || hostname === 'www.app.promotezap.com' || hostname === 'app.promotezap';

export const isAdwixy =
  hostname === 'whatsapp.adwixy.com' || hostname === 'www.whatsapp.adwixy.com' || hostname === 'whatsapp.adwixy';

export const isVibb =
  hostname === 'www.app.vibbdigital.com' || hostname === 'app.vibbdigital.com' || hostname === 'app.vibbdigital';

export const isStarqard =
  hostname === 'starqadsbulkify.tech' || hostname === 'www.starqadsbulkify.tech' || hostname === 'starqadsbulkify';

// eslint-disable-next-line no-nested-ternary
export const HOST_API = isDesk ? '' : isLocal ? 'http://localhost:8080' : 'https://app.whatsera.com';
// export const HOST_API = ""

export const HOST = isLocal ? 'http://localhost:8080' : 'https://app.whatsera.com';

export const HOST_URL =
  (isSendPost && 'https://sendpost.in') ||
  (isSukum && 'https://mywhatsapp.sukamapps.com') ||
  (isMorphysms && 'https://whatsapp.morphysms.com') ||
  (isPromotezap && 'https://app.promotezap.com') ||
  (isKglCorp && 'https://whatscrmdj.online') ||
  (isAdwixy && 'https://Whatsapp.adwixy.com') ||
  (isUPM && 'https://upmapps.in') ||
  (isblackben && 'https://whatsapp.blackben.in/') ||
  (isXtechno && 'https://app.xtechnosys.com') ||
  (isVibb && 'https://app.vibbdigital.com') ||
  (isQruplus && 'https://whatsapp.ranjeetsaini.com/') ||
  (isStarqard && 'https://starqadsbulkify.tech') ||
  (isShyamInfo && 'https://wa.shyaminfotech.com') ||
  'https://app.whatsera.com';

export const WhatsEraAPIKey = '62dfdd65a3961a53aa8ee73d';

export const WhatsEraResellerId = '62c6bb7429c592c619f8ef4a';
export const MorphyResellerId = '64550260100c94fe3b2acc94';
export const SukamResellerId = '645e08b57eb40a3c9c735933';
export const KgplCorpResellerId = '62f749b94e8738e6125188bc';
export const PromotezapResellerId = '637bc313b98ddc39236905ce';
export const adwixyResellerId = '64e89e6e57315189e278a71c';
export const UPMResellerId = '64f3268ce282251876c0aca0';
export const BlackBenResellerId = '656433d347e55da0d4af8ba2';
export const whatseraPartnerId = '659920289643434716970e98';
export const xtechnosysResellerId = '65e0787f53f754e8bfc25e09';

export const qroPlusResellerId = '66cc2858b342384ba72e3d6f';
export const StarqadsResellerId = '66e15777bd72f6fa5d340912';
export const SyamInfoResellerId = '671f5bbab595c3970ffea241';

export const TITLE =
  (isMorphysms && 'Morphy Whatsapp Marketing. Better Way to Market A Business') ||
  (isSukum && 'Sukam Apps WhatsApp Marketing') ||
  (isKglCorp && 'DJ CRM Channel whatsapp marketing solution') ||
  (isPromotezap && 'Promotezap') ||
  (isSendPost && 'Sendpost a complete whatsapp marketing solution') ||
  (isAdwixy && 'Adwixy WhatsApp marketing Services') ||
  (isUPM && 'UPM Sender') ||
  (isblackben && 'Black Ben WhatsApp Marketing Solution') ||
  (isXtechno && 'Xtechnosys WhatsApp Marketing') ||
  (isVibb && 'Get in touch with META WA Business is easy now') ||
  (isQruplus && 'Revolutionize Your WhatsApp Marketing') ||
  (isStarqard && 'Starqads Bulkify WhatsApp Marketing') ||
  (isShyamInfo && 'Syam Infotech WhatsApp Marketing') ||
  'WhatsEra a complete whatsapp marketing solution';

export const NAME =
  (isMorphysms && 'Morphy') ||
  (isSukum && 'Sukam App') ||
  (isKglCorp && 'DJ CRM Channel') ||
  (isPromotezap && 'Promotezap') ||
  (isSendPost && 'Sendpost') ||
  (isAdwixy && 'Adwixy') ||
  (isUPM && 'UPM Sender') ||
  (isblackben && 'Black Ben') ||
  (isXtechno && 'Xtechnosys') ||
  (isVibb && 'VIBB Digital') ||
  (isQruplus && 'Qroplus') ||
  (isStarqard && 'StarqadsBulkify ') ||
  (isShyamInfo && 'Syam Infotech ') ||
  'WhatsEra';
export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.channels.root; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },
];

export const defaultLang = allLangs[0]; // English

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { isUPM, isWhatsEra } from '../config';

// ----------------------------------------------------------------------

LogoXTechnoSys.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoXTechnoSys({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 50, height: 31, ...sx }}>
      {isWhatsEra ? (
        <svg width="100%" height="100%" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>
          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              fillRule="evenodd"
              fill="url(#BG1)"
              clipRule="evenodd"
              d="M8.58403 20.5251C12.2443 17.8467 12.4111 17.7244 12.4188 17.7191C12.4198 17.7181 12.4719 17.6801 16.1207 15.0098C13.966 9.32988 12.5923 5.9684 11.9993 4.9254C11.1102 3.36089 10.1402 2.17632 8.94726 1.49283C6.43934 -0.176062 3.32164 -0.254093 0.914062 1.80925L8.58403 20.5251Z"
            />
            <path
              fillRule="evenodd"
              fill="url(#BG2)"
              clipRule="evenodd"
              d="M36.4769 18.3463C32.6529 27.8195 28.7 36.7447 24.1593 23.6523C23.5381 21.9692 23.0854 18.6982 22.0711 18.6982V18.7148C21.0569 18.7148 20.6042 21.9858 19.9831 23.6689C15.4422 36.7613 11.4893 27.8362 7.66529 18.3629C7.37751 17.6485 7.10107 16.9667 6.8645 16.3741C15.628 24.2291 14.8901 0.49881 22.0711 0.310248V0.293629C29.2522 0.48219 28.5143 24.2124 37.2777 16.3577C37.0413 16.9501 36.7649 17.6319 36.4769 18.3463"
            />
            <path
              fillRule="evenodd"
              fill="url(#BG3)"
              clipRule="evenodd"
              d="M36.9472 0.293614C39.138 0.293614 40.9141 2.80845 40.9141 5.91043C40.9141 9.01242 39.138 11.5273 36.9472 11.5273C34.7564 11.5273 32.9802 9.01242 32.9802 5.91043C32.9802 2.80845 34.7564 0.293614 36.9472 0.293614Z"
            />
          </g>
        </svg>
      ) : (
        <svg width="100%" height="100%" viewBox="0 0 97 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M54.0172 54.1284L56.2124 38.85H62.6997L57.5775 64.75H51.8493L48.2452 49.3719L44.6248 64.75H38.891L33.7635 38.85H40.2617L42.4077 54.4803L45.8698 38.85H50.5933L54.0172 54.1284ZM20.2592 54.1284L22.4489 38.85H28.9417L23.8195 64.75H18.0858L14.4818 49.3719L10.8668 64.75H5.13305L0 38.85H6.49822L8.64974 54.4803L12.1064 38.85H16.8299L20.2592 54.1284ZM87.7806 54.1284L89.9704 38.85H96.4577L91.341 64.75H85.6073L82.0032 49.3719L78.3882 64.75H72.6545L67.5215 38.85H74.0197L76.1712 54.4803L79.6278 38.85H84.3513L87.7806 54.1284Z"
            fill="#3F51B5"
          />
          <path d="M10.8559 20.7212H85.6073V25.9H10.8559V20.7212Z" fill="#2196F3" />
          <path
            d="M10.8559 20.7212H85.6073V25.9H10.8559V20.7212ZM10.8559 77.7H85.6073V82.8847H10.8559V77.7Z"
            fill="#2196F3"
          />
          <path
            d="M8.26204 33.6712C13.2913 20.9499 23.4264 11.1025 35.9041 7.12602C31.1642 13.079 27.511 22.3986 25.5997 33.6712H30.5362C33.2229 18.862 39.0221 7.76531 45.8207 5.60697V33.6712H50.6425V5.60697C57.4356 7.76531 63.2403 18.862 65.9215 33.6712H70.8634C68.9522 22.3986 65.299 13.079 60.5591 7.12602C73.0313 11.1025 83.1718 20.9499 88.1956 33.6712H93.3505C86.4865 14.035 68.8812 0 48.2288 0C27.582 0 9.97124 14.035 3.11261 33.6712H8.26204ZM88.1956 69.9347C83.1718 82.6501 73.0313 92.5033 60.5591 96.4798C65.299 90.5268 68.9522 81.2014 70.8634 69.9347H65.9215C63.2403 84.7439 57.4356 95.8347 50.6425 97.9989V69.9347H45.8207V97.9989C39.0221 95.8406 33.2229 84.7439 30.5362 69.9347H25.5997C27.511 81.2014 31.1642 90.5268 35.9041 96.4798C23.4264 92.5033 13.2913 82.6501 8.26204 69.9347H3.11261C9.97124 89.565 27.582 103.6 48.2288 103.6C68.8812 103.6 86.4865 89.565 93.3505 69.9347H88.1956Z"
            fill="#2196F3"
          />
        </svg>
      )}
    </Box>
  );

  const logoUPM = (
    <Box sx={{ width: 200, height: 60, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 356 294" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_205_2)">
          <mask
            id="mask0_205_2"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="100%"
            height="100%"
          >
            <path d="M356 0H0V294H356V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_205_2)">
            <path
              d="M178.653 265.896C108.962 265.641 54.7242 224.91 52.8432 170.743C52.5634 162.646 52.5427 145.753 52.781 120.066C53.1541 80.6604 81.5714 47.1027 129.172 33.4121C148.417 27.88 171.004 27.5274 191.851 28.2448C248.25 30.1779 297.265 64.0761 302.239 108.212C302.892 114.06 303.327 122.571 303.327 129.805C303.39 170.642 303.4 211.475 303.359 252.303C303.359 259.635 301.042 265.86 290.052 265.921C263.676 266.075 226.543 266.067 178.653 265.896Z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M77.6383 168.944C77.7782 203.973 108.512 235.573 152.443 244.181C172.994 248.218 201.66 246.735 224.45 246.455C224.65 246.452 224.831 246.358 224.911 246.214C224.991 246.07 224.957 245.903 224.823 245.786C180.192 208.058 198.722 150.937 260.189 133.83C265.319 132.407 271.863 132.006 277.631 131.021C278.149 130.932 278.413 130.677 278.424 130.255C278.569 121.388 278.434 115.576 278.019 112.82C274.413 88.7458 260.142 70.4796 235.207 58.0211C213.848 47.3459 189.877 46.5677 164.615 47.7471C113.44 50.1302 77.6383 80.8306 77.5761 120.395C77.545 136.566 77.5657 152.749 77.6383 168.944Z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M246.089 234.491C255.033 240.724 265.521 244.509 277.553 245.847C277.79 245.872 278.028 245.815 278.207 245.691C278.386 245.567 278.488 245.389 278.486 245.202L278.455 151.472C278.455 151.224 278.315 150.988 278.072 150.829C277.829 150.669 277.508 150.602 277.195 150.645C225.351 157.819 207.054 207.292 246.089 234.491Z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M152.443 244.181C108.512 235.573 77.7782 203.973 77.6383 168.944C77.5657 152.749 77.545 136.566 77.5761 120.395C77.6383 80.8306 113.44 50.1302 164.615 47.7471C189.877 46.5677 213.848 47.3459 235.207 58.0211C260.142 70.4796 274.413 88.7458 278.019 112.82C278.434 115.576 278.569 121.388 278.424 130.255C278.413 130.677 278.149 130.932 277.631 131.021C271.863 132.006 265.319 132.407 260.189 133.83C198.722 150.937 180.192 208.058 224.823 245.786C224.957 245.903 224.991 246.07 224.911 246.214C224.831 246.358 224.65 246.452 224.45 246.455C201.66 246.735 172.994 248.218 152.443 244.181ZM107.781 112.856C109.118 133.246 137.255 129.915 154.309 129.769C154.796 129.769 155.06 129.578 155.101 129.197C155.651 123.345 155.744 117.509 155.381 111.689C153.982 88.6121 130.198 92.0651 108.543 92.126C107.973 92.126 107.677 92.3489 107.657 92.7946C107.294 99.5143 107.335 106.202 107.781 112.856ZM201.396 129.587C216.008 130.049 235.938 132.529 244.706 121.769C250.317 114.862 248.996 101.026 248.39 92.8676C248.359 92.4623 248.089 92.2515 247.581 92.2353C226.144 91.7368 200.929 89.2565 200.634 111.616C200.551 117.485 200.582 123.297 200.727 129.052C200.738 129.392 200.961 129.57 201.396 129.587Z"
              fill="white"
            />
            <path
              d="M154.309 129.769C137.255 129.915 109.118 133.246 107.781 112.856C107.335 106.202 107.294 99.5143 107.657 92.7946C107.677 92.3489 107.973 92.126 108.543 92.126C130.198 92.0651 153.982 88.6121 155.381 111.689C155.744 117.509 155.651 123.345 155.101 129.197C155.06 129.578 154.796 129.769 154.309 129.769Z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M201.396 129.587C200.961 129.57 200.738 129.392 200.727 129.052C200.582 123.297 200.551 117.485 200.634 111.616C200.929 89.2565 226.144 91.7368 247.581 92.2353C248.089 92.2515 248.359 92.4623 248.39 92.8676C248.996 101.026 250.317 114.862 244.706 121.769C235.938 132.529 216.008 130.049 201.396 129.587Z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M243.783 242.868C200.017 212.373 220.532 156.904 278.659 148.861C279.009 148.813 279.369 148.888 279.641 149.067C279.914 149.246 280.071 149.51 280.071 149.788L281.034 239.798C281.036 240.008 262.029 236.799 261.829 236.937C261.628 237.076 279.325 255.628 279.06 255.6C265.569 254.101 253.81 249.856 243.783 242.868Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_205_2">
            <rect width="356" height="294" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{isUPM ? logoUPM : logo}</>;
  }

  return <RouterLink to="/">{isUPM ? logoUPM : logo}</RouterLink>;
}

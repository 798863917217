/* eslint-disable no-nested-ternary */
// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOT_BLOG = '/demo';

// ----------------------------------------------------------------------
const { hostname } = window.location;
const isSukum =
  hostname === 'mywhatsapp.sukamapps.com' ||
  hostname === 'www.mywhatsapp.sukamapps.com' ||
  hostname === 'mywhatsapp.sukamapps';
const isQruplus =
  hostname === 'whatsapp.ranjeetsaini.com' ||
  hostname === 'www.whatsapp.ranjeetsaini.com' ||
  hostname === 'whatsapp.ranjeetsaini';
const isShyamInfo =
  hostname === 'wa.shyaminfotech.com' || hostname === 'wa.shyaminfotech' || hostname === 'www.wa.shyaminfotech.com';

const isVibb =
  hostname === 'www.app.vibbdigital.com' || hostname === 'app.vibbdigital.com' || hostname === 'app.vibbdigital';
const isStarqard =
  hostname === 'starqadsbulkify.tech' || hostname === 'www.starqadsbulkify.tech' || hostname === 'starqadsbulkify';
const isPromotezap =
  hostname === 'app.promotezap.com' || hostname === 'www.app.promotezap.com' || hostname === 'app.promotezap';
const isUPM = hostname === 'upmapps.in' || hostname === 'www.upmapps.in' || hostname === 'upmapps';
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: (token) => path(ROOTS_AUTH, `/new-password/${token}`),
};

export const PATH_PAGE = {
  resellerPage: 'reseller',
  wachatbot: '/whatsapp-chat-bot',
  wacrm: '/whatsapp-crm',
  wamarketing: '/whatsapp-marketing',
  waolead: 'wao-lead-scraper',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  privacy: '/privacy-policy',
  terms: '/terms-&-condition',
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  demoquery: '/demo-query',
  desktop: '/desktop-tool',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  root: ROOT_BLOG,
  blog: {
    root: path(ROOT_BLOG, '/demo'),
    posts: path(ROOT_BLOG, '/demo/videos'),
  },
  payment: {
    root: path(`/payment`),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  unsubscribers: path(ROOTS_DASHBOARD, '/unsubscribers'),
  lead: path(ROOTS_DASHBOARD, '/leads'),
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  greenTick: {
    root: path(ROOTS_DASHBOARD, '/green-tick'),
  },
  hooks: path(ROOTS_DASHBOARD, '/hooks'),
  api: {
    root: path(ROOTS_DASHBOARD, '/api'),
    list: path(ROOTS_DASHBOARD, '/api/list'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    list: path(ROOTS_DASHBOARD, '/admin/users'),
    view: (id) => path(ROOTS_DASHBOARD, `/admin/${id}`),
  },
  dashboard: {
    root: path(ROOTS_DASHBOARD, '/dashboard'),
    users: path(ROOTS_DASHBOARD, '/dashboard/users'),
    queries: path(ROOTS_DASHBOARD, '/dashboard/queries'),
    view: (id) => path(ROOTS_DASHBOARD, `/dashboard/${id}/channels`),
    viewUsers: (id) => path(ROOTS_DASHBOARD, `/dashboard/${id}/users`),
    channels: path(ROOTS_DASHBOARD, '/dashboard/channels'),
  },
  businesstools: {
    root: path(ROOTS_DASHBOARD, '/businesstools'),
    // list: path(ROOTS_DASHBOARD, '/businesstools/catalogue'),
    // orders: path(ROOTS_DASHBOARD, '/businesstools/orders'),
    invoice: path(ROOTS_DASHBOARD, '/businesstools/invoice'),
    icreate: path(ROOTS_DASHBOARD, '/businesstools/invoice/create'),
    detail: (id) => path(ROOTS_DASHBOARD, `/businesstools/orders/${id}/detail`),
    // create: (channelId, id) => path(ROOTS_DASHBOARD, `/businesstools/orders/${channelId}/${id}/create`),
    edit: (id) => path(ROOTS_DASHBOARD, `/businesstools/orders/${id}/edit`),
  },
  autoReply: {
    root: path(ROOTS_DASHBOARD, '/autoreply'),
    add: (id) => path(ROOTS_DASHBOARD, `/autoreply/add/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/autoreply/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/autoreply/${id}/view`),
  },
  welcome: {
    root: path(ROOTS_DASHBOARD, '/welcome'),
    add: (id) => path(ROOTS_DASHBOARD, `/welcome/add/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/welcome/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/welcome/${id}/view`),
  },
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
    list: path(ROOTS_DASHBOARD, '/contacts/list'),
    new: path(ROOTS_DASHBOARD, '/contacts/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/contacts/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/contacts/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/contacts/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/contacts/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  integration: {
    root: path(ROOTS_DASHBOARD, '/integration'),
    speadSheet: path(ROOTS_DASHBOARD, '/integration/spead-sheet'),
    zoho: path(ROOTS_DASHBOARD, '/integration/zoho'),
    custom: path(ROOTS_DASHBOARD, '/integration/custom'),
    webhook: path(ROOTS_DASHBOARD, '/integration/webhook'),
  },
  channels: {
    root: path(ROOTS_DASHBOARD, '/channels'),
    list: path(ROOTS_DASHBOARD, '/channels/list'),
    new: path(ROOTS_DASHBOARD, '/channels/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/channels/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/channels/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/channels/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/channels/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  template: {
    root: path(ROOTS_DASHBOARD, '/template'),
    list: path(ROOTS_DASHBOARD, '/template/list'),
    add: path(ROOTS_DASHBOARD, '/template/new'),
    // edit:path(ROOTS_DASHBOARD,'/template/edit'),
    edit: (id) => path(ROOTS_DASHBOARD, `/template/${id}/edit`),
  },
  list: {
    root: path(ROOTS_DASHBOARD, '/list_msg'),
    add: path(ROOTS_DASHBOARD, '/list_msg/add'),
    edit: (id) => path(ROOTS_DASHBOARD, `/list_msg/${id}/edit`),
  },
  button: {
    root: path(ROOTS_DASHBOARD, '/button'),
    list: path(ROOTS_DASHBOARD, '/button/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/button/edit/${id}`),
    new: path(ROOTS_DASHBOARD, '/button/new'),
  },
  campaign: {
    root: path(ROOTS_DASHBOARD, '/campaign'),
    list: path(ROOTS_DASHBOARD, '/campaign/list'),
    new: path(ROOTS_DASHBOARD, '/campaign/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/campaign/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/campaign/${id}/edit`),
    copy: (id) => path(ROOTS_DASHBOARD, `/campaign/${id}/copy`),

    demoEdit: path(ROOTS_DASHBOARD, '/campaign/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/campaign/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  attachment: {
    root: path(ROOTS_DASHBOARD, '/attachment'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
};

export const PATH_DOCS = isSukum
  ? 'https://documenter.getpostman.com/view/20567666/2s93eeQUT1'
  : isPromotezap
  ? 'https://documenter.getpostman.com/view/14153053/2s9XxsVGSC'
  : isUPM
  ? 'https://documenter.getpostman.com/view/20567666/2s9YC31Dfj'
  : isVibb
  ? 'https://documenter.getpostman.com/view/14153053/2sAXjGbDEG'
  : isQruplus
  ? 'https://documenter.getpostman.com/view/14153053/2sAXjGatSC'
  : isStarqard
  ? 'https://documenter.getpostman.com/view/20567666/2sAXqmAQkL'
  : isShyamInfo
  ? 'https://documenter.getpostman.com/view/20567666/2sAY4uBiLw'
  : 'https://documenter.getpostman.com/view/14153053/UzQpxTSw';
